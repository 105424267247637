.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html,
body {
    height: 100%;
}

/*разделитель*/
.or-separator {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    position: relative;
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 1em;
}

.or-text {
    position: absolute;
    left: 46%;
    top: 0;
    background: #fff;
    padding: 10px;
    color: rgba(0, 0, 0, 0.45);
}

/*label {
  font-size: 70%;
  font-weight: 700;
  color: #595959;
}*/
.button-text {
    font-size: 13px;
}

.login-container {
    text-align: center;
}

.login-content {
    background: #fff;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 2px;
    width: 500px;
    display: inline-block;
    margin-top: 5%;
    vertical-align: middle;
    position: relative;
    padding: 35px;
}

.logo-content {
    margin-top: 5%;
    vertical-align: middle;
    position: relative;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.social-btn {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
}

.signup-link {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
}

.login-title {
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.65);
}

.ag-header-cell-label .ag-header-icon.ag-sort-order {
    display: none
}

.block {
    margin: 0.75rem;
}

.block.form {
    padding: 0.5rem 1rem;
}

.full-width {
    width: 100% !important;
}
