body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.nav-link.active {
    color: #fff;
    background-color: #007bff;
    border-radius: 0.25rem;
}

.ag-theme-balham .ag-header-row:first-child .ag-header-cell {
    background-color: #b5b5b5;
    padding-left: 2px;
    text-align: right;
    border-right-width: 1px;
    border-right-color: #989898;
}

/*.ag-header-icon .ag-header-cell-menu-button {*/
/*    display: none*/
/*}*/

/*.ag-header-cell-label .ag-header-icon.ag-sort-order {*/
/*    display: none*/
/*}*/

.ag-header-cell-label {
    font-size: 12px;
    font-weight: bold;
}

.red-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #e81224;
    display: inline-block;
    border: 1px solid #282c34;
}

.green-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #16c60c;
    display: inline-block;
    border: 1px solid #282c34;
}

.toast.centered {
    max-width: 700px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.loading {
    text-align: center;
    position: fixed;
    top: 30%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.toast.error {
    background-color: #ff8892;
}

.toast.success {
    background-color: #cbffb5;
}


.raw-data-export-container .react-bootstrap-daterangepicker-container,
.events-form .react-bootstrap-daterangepicker-container {
    width: 100% !important;
}

.date-range-select {
    width: 100% !important;
    opacity: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s;
}

.App {
    font-family: sans-serif;
    text-align: center;
}

.box2 {
    display: inline-block;
}

.handsontable .totalNoResult {
    font-size: 0.75rem;
    background-color: #ffffff;
}

.handsontable .totalBad {
    font-size: 0.75rem;
    background-color: #ffadad;
}

.handsontable .totalNormal {
    font-size: 0.75rem;
    background-color: #fdffb6;
}

.handsontable .totalGood {
    font-size: 0.75rem;
    background-color: #aaff99;
}

.handsontable .totalBest {
    font-size: 0.75rem;
    background-color: #88ff70;
}

.handsontable .leftColumn {
    border-left: 2px solid #b0b0b0;
}

.handsontable th {
    white-space: normal !important;
    font-size: 0.7rem;
}

.handsontable .htAutocompleteArrow {
    display: none;
}

.handsontable .htDimmed {
    color: rgba(0, 0, 0, 0.87);
}

.listbox .htDimmed {
    font-size: 0.7rem;
}
